.neusta-advice-teaser {
    border-radius: 6px;
    overflow: hidden;
    position: relative;
}

.neusta-advice-teaser > a {
    display: block;
    text-decoration: none;
}

.neusta-advice-teaser .content {
    padding: 20px 24px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 14%;

    @media only screen and (min-width: 1024px) {
        min-height: 20%;
        padding: 28px 40px 54px 40px;
    }
}

.neusta-advice-teaser {
    &.dark-grey .content {
        background-color: #646363;
    }

    &.dark-beige .content {
        background-color: #AA9678;
    }

    &.medium-beige .content {
        background-color: #E9E4DD;

        .headline {
            color: #646363;
        }
    }

    &.light-beige .content {
        background-color: #F8F7F2;

        .headline {
            color: #646363;
        }
    }

    &.corporate-red .content {
        background-color: #E2001A;
    }

    &.light-orange .content {
        background-color: #F9B234;
    }

    &.sunny-yellow .content {
        background-color: #FFCC00;
    }

    &.dark-apple-green .content {
        background-color: #7AA62B;
    }

    &.medium-turquoise-green .content {
        background-color: #178E76;
    }

    &.dark-turquoise-green .content {
        background-color: #004D4D;
    }

    &.medium-red .content {
        background-color: #D51031;
    }

    &.dark-purple .content {
        background-color: #752A56;
    }

    &.blue .content {
        background-color: #0067AB;
    }

    &.dark-royal-blue .content {
        background-color: #2A2081;
    }

    &.dark-denim-blue .content {
        background-color: #004564;
    }

    &.dark-violet .content {
        background-color: #7F2886;
    }

    &.medium-apple-green .content {
        background-color: #86B741;
    }

    &.turquoise-blue .content {
        background-color: #017E97;
    }

    &.medium-purple .content {
        background-color: #A7176E;
    }

    &.dark-sun-yellow .content {
        background-color: #F2B300;
    }
}

.neusta-advice-teaser .headline {
    color: #fff;
    font-family: $font-base;
    font-size: font-size(m);
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    display: block;

    @media only screen and (min-width: 1024px) {
        font-size: font-size(l);
        line-height: 32px;
    }
}

.neusta-advice-teaser .media-wrapper {
    height: 450px;
    width: 100%;
    position: relative;

    @media only screen and (min-width: 1024px) {
        height: 550px;
    }

    img {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
    }
}
